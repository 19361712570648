import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import piniaAuthStore from '@/store/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'products',
    component: () => import('@/views/ProductsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/AuthSettingsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('../views/AuthLoginView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('../views/AuthRegisterView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/create-conf/type/:roomType',
    name: 'create-conf',
    component: () => import('../views/RoomCreate.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/room/:roomId',
    name: 'room-edit',
    component: () => import('../views/RoomEditView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/room/list',
    name: 'room-list',
    component: () => import('../views/RoomListView.vue'),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from) => {
  const authStore = piniaAuthStore();
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !(await authStore.isLoggedInBefore())) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/sign-in',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  }
});

export default router;
