import { defineStore } from 'pinia';
import { computed, Ref, ref } from 'vue';
import type { LoginUserdata, SignUpUserdataWithConfirmPassword, User } from '@/types/User';
import axios from 'axios';
import { useNotification } from '@kyvg/vue3-notification';
import type { NotificationItemWithTimer } from '@kyvg/vue3-notification/dist/src/util';
import { UpdateUserdata } from '@/types/User';
import type { RoomItem, RoomList } from '@/types/room';
import { LanguageList } from '@/types/room';

const { notify } = useNotification();

const API_SERVER = 'https://demo2account.verspeak.com';

const instance = axios.create({
  withCredentials: true,
  baseURL: API_SERVER,
});

export default defineStore('auth', () => {
  const user: Ref<User | null> = ref(null);
  const isLoggedIn: Ref<boolean> = ref(false);
  const isValidRoom: Ref<boolean> = ref(false);

  const notificationOpt: Ref<NotificationItemWithTimer> = ref({
    length: 0,
  });

  function clearNotificationOpt() {
    notificationOpt.value = {
      length: 0,
    };
  }

  async function logout() {
    await instance
      .get('https://demo2account.verspeak.com/auth/logout')
      .then(async (res) => {
        const { data } = res;

        if (data.error) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.text = 'Ошибка при выходе из профиля!';
        }

        if (data.result) {
          notificationOpt.value.type = 'success';
          notificationOpt.value.text = 'Вы успешно вышли из профиля!';

          isLoggedIn.value = false;
          user.value = null;
        }
      })
      .catch(async (error) => {
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
      })
      .finally(() => {
        notify(notificationOpt.value);
      });
  }

  async function getUser() {
    notificationOpt.value.title = 'Получение пользователя';

    await instance
      .get('https://demo2account.verspeak.com/profile/user-data')
      .then(async (res) => {
        const { data } = res;

        if (data.error && isLoggedIn.value) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.text =
            'Ошибка получение пользователя! Пожалуйста, перезайдите в профиль!';
          notify(notificationOpt.value);
          await logout();
        }

        if (data.result) {
          user.value = data.result;
          isLoggedIn.value = true;
        }
      })
      .catch(async (error) => {
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
        notify(notificationOpt.value);

        await logout();
      });
  }

  async function login(userdata: LoginUserdata) {
    clearNotificationOpt();
    notificationOpt.value.title = 'Авторизация';

    await instance
      .post('https://demo2account.verspeak.com/auth/login', {
        email: userdata.email,
        password: userdata.password,
      })
      .then(async (res) => {
        const { data } = res;

        if (data.error) {
          notificationOpt.value.type = 'error';
        }

        if (data.error && data.error.code === 1) {
          notificationOpt.value.text = 'Произошла ошибка при отправке запроса на сервер!';
        }

        if (
          data.error &&
          (data.error.code === 2 || data.error.code === 5 || data.error.code === 6)
        ) {
          notificationOpt.value.text = 'Неверный логин или пароль!';
        }

        if (data.result && data.result.success) {
          notificationOpt.value.type = 'success';
          notificationOpt.value.text = 'Вы успешно вошли в аккаунт!';

          isLoggedIn.value = true;

          await getUser();
        }
      })
      .catch((error) => {
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
      })
      .finally(() => {
        notify(notificationOpt.value);
      });
  }

  async function signUp(userdata: SignUpUserdataWithConfirmPassword) {
    clearNotificationOpt();
    notificationOpt.value.title = 'Регистрация';

    if (userdata.password !== userdata.confirmPassword) {
      notificationOpt.value.type = 'error';
      notificationOpt.value.text = 'Введенные пароли не совпадают!';
      notify(notificationOpt.value);
      return;
    }
    await instance
      .post('https://demo2account.verspeak.com/auth/register', {
        email: userdata.email,
        password: userdata.password,
        name: userdata.name,
        phoneNumber: userdata.phoneNumber,
      })
      .then(async (res) => {
        const { data } = res;

        if (data.error) {
          notificationOpt.value.type = 'error';
        }

        if (data.error && data.error.code === 1) {
          notificationOpt.value.text = 'Произошла ошибка при отправке запроса на сервер!';
        }

        if (data.error && data.error.code === 2) {
          notificationOpt.value.text = 'Проверьте введенные данные!';
        }

        if (data.error && data.error.code === 7) {
          notificationOpt.value.text = 'Введенный Email уже используется!';
        }

        if (data.result && data.result.success) {
          notificationOpt.value.type = 'success';
          notificationOpt.value.text = 'Вы успешно зарегистрировали аккаунт!';

          isLoggedIn.value = true;

          await getUser();
        }
      })
      .catch((error) => {
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
      })
      .finally(() => {
        notify(notificationOpt.value);
      });
  }

  async function updateUser(userdata: UpdateUserdata) {
    clearNotificationOpt();
    notificationOpt.value.title = 'Обновление пользователя';

    instance
      .post('https://demo2account.verspeak.com/profile/update', {
        userEmail: userdata.userEmail,
        userName: userdata.userName,
        phoneNumber: userdata.phoneNumber,
      })
      .then(async (res) => {
        const { data } = res;

        if (data.error && isLoggedIn.value) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.text = 'Ошибка обновления профиля! Пожалуйста, попробуйте еще раз!';
        }

        if (data.result) {
          await getUser();

          notificationOpt.value.type = 'success';
          notificationOpt.value.text = 'Профиль успешно обновлен!';
        }
      })
      .catch((error) => {
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
      })
      .finally(() => {
        notify(notificationOpt.value);
      });
  }

  async function isLoggedInBefore() {
    await getUser();

    return isLoggedIn.value;
  }

  async function createRoom(roomId: string, roomType: 1 | 2 | 3, startTime?: number) {
    if (!roomId) {
      isValidRoom.value = false;

      notificationOpt.value.title = 'Произошла ошибка при создании комнаты!';
      notificationOpt.value.type = 'error';
      notificationOpt.value.text = 'Идентификатор комнаты не может быть пустым.';

      return;
    }

    const postData: {roomId: string, roomType: 1 | 2 | 3, startTime?: number} = {
      roomId,
      roomType,
    };

    if (startTime) {
      postData.startTime = startTime;
    }

    await instance
      .post('/rooms/create', postData)
      .then((res) => {
        const { data } = res;

        if (data.result?.success) {
          isValidRoom.value = true;
          notificationOpt.value.title = 'Комната успешно создана!';
          notificationOpt.value.type = 'success';
        }

        if (data.error) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.title = 'Произошла ошибка при создании комнаты';

          const errorMsg = {
            INTERNAL_SERVICE_ERROR:
              'Произошла непредвиденная ошибка или такая комната уже существует, попробуйте еще раз!',
            INPUT_ERROR: 'Идентификатор комнаты не может быть пустым, попробуйте еще раз!',
            TOKEN_ERROR:
              'Вам необходимо зайти в свой профиль, страница откроется через несколько секунд!',
          };
          switch (data.error.code) {
            case 1:
              notificationOpt.value.text = errorMsg.INTERNAL_SERVICE_ERROR;
              break;
            case 2:
              notificationOpt.value.text = errorMsg.INPUT_ERROR;
              break;
            case 3:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            case 4:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        isValidRoom.value = false;
        notificationOpt.value.title = 'Произошла ошибка при создании комнаты!';
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
      })
      .finally(() => {
        notify(notificationOpt.value);
        clearNotificationOpt();
      });
  }

  const getRoomList = async () => {
    const roomList:RoomList = [];

    await instance
      .get('/rooms/list')
      .then((res) => {
        const { data } = res;

        if (data.result) {
          roomList.push(...data.result);
        }

        if (data.error) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.title = 'Произошла ошибка при получении списка комнат!';

          const errorMsg = {
            INTERNAL_SERVICE_ERROR:
              'Произошла непредвиденная ошибка, попробуйте перезагрузить страницу!',
            TOKEN_ERROR:
              'Вам необходимо перезайти в свой профиль!',
          };
          switch (data.error.code) {
            case 1:
              notificationOpt.value.text = errorMsg.INTERNAL_SERVICE_ERROR;
              break;
            case 3:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            case 4:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            default:
              break;
          }

          notify(notificationOpt.value);
        }

        console.log(roomList, 'roomList');
      })
      .catch((error) => {
        notificationOpt.value.title = 'Произошла ошибка при получении списка комнат!';
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
        notify(notificationOpt.value);
      })
      .finally(() => {
        clearNotificationOpt();
      });

    return roomList.sort((a, b) => b.startTime - a.startTime);
  };

  function prettyNumber(number: number) {
    if (number < 10) {
      return `0${number}`;
    }
    return number;
  }

  function getDateStr(datetime: number): string {
    const date = new Date(datetime);

    return `${prettyNumber(date.getDate())}/${prettyNumber(date.getMonth() + 1)}/${prettyNumber(
      date.getFullYear(),
    )} ${prettyNumber(date.getHours())}:${prettyNumber(date.getMinutes())}:${prettyNumber(
      date.getSeconds(),
    )}`;
  }

  async function getLanguages() {
    const languageList:LanguageList = [];

    await instance
      .get('/translator/languages')
      .then((res) => {
        const { data } = res;

        if (data.result) {
          languageList.push(...data.result);
        }

        if (data.error) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.title = 'Произошла ошибка при получении списка языковых пар!';

          const errorMsg = {
            INTERNAL_SERVICE_ERROR:
              'Произошла непредвиденная ошибка, попробуйте перезагрузить страницу!',
            TOKEN_ERROR:
              'Вам необходимо перезайти в свой профиль!',
          };
          switch (data.error.code) {
            case 1:
              notificationOpt.value.text = errorMsg.INTERNAL_SERVICE_ERROR;
              break;
            case 3:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            case 4:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            default:
              break;
          }

          notify(notificationOpt.value);
        }
      })
      .catch((error) => {
        notificationOpt.value.title = 'Произошла ошибка при получении списка языковых пар!';
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
        notify(notificationOpt.value);
      })
      .finally(() => {
        clearNotificationOpt();
      });

    return languageList;
  }

  const getRoomById = async (roomId: string) => {
    const roomItem:Ref<RoomItem|null> = ref(null);

    await instance
      .get('/rooms/list', {
        params: {
          roomId,
        },
      })
      .then((res) => {
        const { data } = res;
        const roomInList = data.result[0];

        if (roomInList) {
          roomItem.value = roomInList;
        }

        if (data.error) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.title = 'Произошла ошибка при получении списка комнат!';

          const errorMsg = {
            INTERNAL_SERVICE_ERROR:
              'Произошла непредвиденная ошибка, попробуйте перезагрузить страницу!',
            TOKEN_ERROR:
              'Вам необходимо перезайти в свой профиль!',
          };
          switch (data.error.code) {
            case 1:
              notificationOpt.value.text = errorMsg.INTERNAL_SERVICE_ERROR;
              break;
            case 3:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            case 4:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            default:
              break;
          }

          notify(notificationOpt.value);
        }
      })
      .catch((error) => {
        notificationOpt.value.title = 'Произошла ошибка при получении списка комнат!';
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
        notify(notificationOpt.value);
      })
      .finally(() => {
        clearNotificationOpt();
      });

    return roomItem.value;
  };

  async function addTranslator(roomId: string, name: string, from: string, to: string) {
    await instance
      .post('/rooms/add-translator', {
        roomId,
        name,
        langPair: {
          from,
          to,
        },
      })
      .then((res) => {
        const { data } = res;
        console.log(res);

        if (data.result?.success) {
          notificationOpt.value.title = 'Переводчик успешно добавлен!';
          notificationOpt.value.type = 'success';
        }

        if (data.error) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.title = 'Произошла ошибка при добавлении переводчика';

          const errorMsg = {
            INTERNAL_SERVICE_ERROR:
              'Произошла непредвиденная ошибка, попробуйте еще раз!',
            INPUT_ERROR: 'Проверьте введенные поля и попробуйте еще раз!',
            TOKEN_ERROR:
              'Вам необходимо перезайти в свой профиль!',
          };
          switch (data.error.code) {
            case 1:
              notificationOpt.value.text = errorMsg.INTERNAL_SERVICE_ERROR;
              break;
            case 2:
              notificationOpt.value.text = errorMsg.INPUT_ERROR;
              break;
            case 8:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        isValidRoom.value = false;
        notificationOpt.value.title = 'Произошла ошибка при добавлении переводчика!';
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
      })
      .finally(() => {
        notify(notificationOpt.value);
        clearNotificationOpt();
      });
  }

  async function removeTranslator(roomId: string, translatorId: string) {
    await instance
      .post('/rooms/remove-translator', {
        roomId,
        translatorId,
      })
      .then((res) => {
        const { data } = res;

        if (data.result?.success) {
          notificationOpt.value.title = 'Переводчик успешно удален!';
          notificationOpt.value.type = 'success';
        }

        if (data.error) {
          notificationOpt.value.type = 'error';
          notificationOpt.value.title = 'Произошла ошибка при удалении переводчика';

          const errorMsg = {
            INTERNAL_SERVICE_ERROR:
              'Произошла непредвиденная ошибка, попробуйте еще раз!',
            INPUT_ERROR: 'Проверьте введенные поля и попробуйте еще раз!',
            TOKEN_ERROR:
              'Вам необходимо перезайти в свой профиль!',
          };
          switch (data.error.code) {
            case 1:
              notificationOpt.value.text = errorMsg.INTERNAL_SERVICE_ERROR;
              break;
            case 2:
              notificationOpt.value.text = errorMsg.INPUT_ERROR;
              break;
            case 8:
              notificationOpt.value.text = errorMsg.TOKEN_ERROR;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        isValidRoom.value = false;
        notificationOpt.value.title = 'Произошла ошибка при удалении переводчика!';
        notificationOpt.value.type = 'error';
        notificationOpt.value.text = error;
      })
      .finally(() => {
        notify(notificationOpt.value);
        clearNotificationOpt();
      });
  }

  return {
    isLoggedIn,
    user,
    login,
    logout,
    updateUser,
    signUp,
    getUser,
    isLoggedInBefore,
    createRoom,
    isValidRoom,
    getRoomList,
    getDateStr,
    getRoomById,
    getLanguages,
    removeTranslator,
    addTranslator,
    prettyNumber,
  };
});
